@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700;800&display=swap");

@import "variables/variables";
@import "bootstrap";

// ================================
@import "general";
@import "footer";
@import "header";
@import "home";
@import "article";
@import "jobs";
@import "contact";

// ================================
.form-block {
    position: sticky;
    top: 3rem;

    &__title {
        background-color: $primary-red;
        color: #fff;
        padding: 1rem 1.5rem;
        font-weight: $font-weight-bold;
        font-size: $h5-font-size;
        margin-top: calc(-2em - 2 * 1.5rem);
    }

    &__content {
        background-color: $gray-400;
        padding: 1.5rem 1.5rem;
    }
}

