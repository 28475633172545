.home-banner {
    position: relative;

    &__data {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: rgba(#000, 0.25);
    }

    &__text {
        padding: 1.5rem;
        margin: 1rem 0;
        max-width: 850px;

        @include media-breakpoint-up(md) {
            padding: 3rem;
            margin: 3rem 0;
        }
    }

    .video-container {
        position: relative;
        width: 100%;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            position: relative;
            padding-top: calc(50vh - 105px); // 105 is header height
        }

        video {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
}

.home-section {
    --article-color: #{$primary-purple};

    &__image {
        overflow: hidden;
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            width: 50%;
            flex: 0 0 50%;

            img {
                margin-right: -5rem;
            }
        }
    }
}

.home-jobs-lines {
    border-right: solid 1px $gray-300;

    @include media-breakpoint-down(md) {
        float: left;
        border: none;
        transform: scaleX(-1);
        margin: 0 1rem 1rem 0;
    }

    img {
        width: 6rem;
        transform: scaleX(-100%);
    }
}
