.lines-decorated {
    float: left;
    max-width: 9rem;
    margin: 0 1rem 1rem 0;
    z-index: 9;

    @include media-breakpoint-up(lg) {
        float: none;
        max-width: unset;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.article {
    position: relative;
    --article-color: #{$gray-900};
    
    &__header {
        position: relative;
        padding: 1rem 0;
        background-color: var(--article-color);
        color: #fff;
    }
    
    &__title {
        margin: 0;
    }
    
    &__content {
        margin: 1rem 0 2rem;
        border: solid 0 $gray-300;
        
        @include media-breakpoint-up(lg) {
            min-height: 450px;
            padding: 1rem 2rem 1rem 21rem;
            border-width: 0 1px;
        }
    }

    .section__title,
    .section__subtitle {
        color: var(--article-color)
    }

    @each $color, $value in $theme-colors {
        &--#{$color} {
            --article-color: #{$value};
        }
    }
}

.section {

    &__title {
        font-size: $h2-font-size;
        color: $gray-900;
        line-height: $headings-line-height;
        font-weight: $headings-font-weight;
    }

    &__subtitle {
        font-size: $h5-font-size;
        color: $gray-900;
        line-height: $headings-line-height;
        font-weight: $headings-font-weight;
        margin-top: 1.5rem;
    }

    &:not(:first-child) {
        margin-top: 3rem;
    }
}

.bullet-item {
    padding-right: 1rem;
    position: relative;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0.5rem;
        right: 0;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: var(--article-color);
    }

    &__title {
        font-weight: $headings-font-weight;
        color: var(--article-color)
    }
}