.footer {
    background-color: $gray-900;
    padding: 3rem 0;
    color: #fff;

    .mail-link {
        color: inherit;
        &:hover {
            color: $primary-yellow;
        }
    }

    .footer-box {
        display: flex;
        flex-direction: column;
        border: solid 0 $gray-700;
        align-items: flex-end;
        justify-content: space-around;

        @include media-breakpoint-up(lg) {
            border-width: 0 1px;
            flex-direction: row;
        }
    }

    .contact-box {
        display: grid;
        grid-template-columns: auto auto;
        gap: 0.25rem 1rem;

        span {
            white-space: nowrap;

            &:first-child {
                padding-bottom: 0.3rem;
            }

            &:nth-child(-n + 2) {
                align-self: end;
                margin-bottom: 1rem;
            }

            &:nth-child(2n) {
                justify-self: center;
            }
        }

        .footer-heart {
            @include media-breakpoint-down(sm) {
                max-width: 2rem;
            }
        }
    }
}
