.job {
    padding: 1rem 0;
    margin: 1rem 0;
    border-bottom: solid 1px $gray-500;

    &:first-of-type {
        border-top: solid 1px $gray-500;
    }

    .section__subtitle {
        &:first-child {
            margin-top: 0;
        }
    }
}
