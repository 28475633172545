.header {
    background-color: $gray-900;
    padding: 0.5rem 0;
    color: #fff;
    margin-bottom: 0.5rem;
}

.header-logo {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
}

.social-links {
    display: flex;
    padding: 0.6rem 1rem;
    justify-content: center;

    @include media-breakpoint-down(lg) {
        padding: 0 1rem;
        border-bottom: solid 1px rgba($color: $primary-green, $alpha: 0.5);
        border-top: solid 1px rgba($color: $primary-green, $alpha: 0.5);
    }

    &__item {
        color: inherit;
        padding: 0.25rem;
        display: block;

        @include media-breakpoint-down(lg) {
            padding: 0.65rem 0.5rem;
        }

        &:hover {
            color: $primary-yellow;
        }
    }

    
}

.main-nav {
    @include media-breakpoint-down(lg) {
        order: 3;
    }
}

.navbar-nav {
    --bs-nav-link-font-size: 1.25rem;
    --bs-nav-link-font-weight: 600;

    .nav-item {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(lg) {
            border-top: solid 1px rgba($color: $primary-green, $alpha: 0.5);
        }

        @include media-breakpoint-up(lg) {
            &:not(:first-child) {
                &:before {
                    content: "";
                    display: block;
                    background-color: $primary-green;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 50%;
                }
            }
        }

        .nav-link {
            display: block;
        }
    }
}
