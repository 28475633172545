.contact-details {
    display: grid;
    gap: 0.5rem 1rem;
    grid-template-columns: 120px 1fr;
    
    dt, dd {
        margin: 0;
    }

    .phone-link {
        display: inline-block;
        text-decoration: none;
        direction: ltr;
        text-align: right;
    }
}